<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { IconDeviceLaptop, IconObjectOdometer } from '@cypress-design/vue-icon'
import NavigationTabs from '@/components/Vue/NavigationTabs.vue'
import { scrollTo } from '@/utilities/scrollTo'
import { activeSection, setActiveSectionFromHash, transitioning } from './stores'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

onMounted(() => {
  setActiveSectionFromHash()
})

const { md } = useBreakpoints(breakpointsTailwind)

function selectHash(evt: Event, hash: string) {
  // is we're on mobile, skip animation
  if (!md.value) return
  evt.preventDefault()
  const target = document.getElementById(hash) as HTMLElement
  if (target) {
    activeSection.value = hash
    transitioning.value = true
    scrollTo(target, {
      callback() {
        transitioning.value = false
      },
    })
  }
}

const prevActiveSection = ref()

watch(activeSection, (_, oldVal) => {
  if (transitioning.value) return
  prevActiveSection.value = oldVal
})

const liveActiveSection = computed(() => {
  if (!transitioning.value) {
    return activeSection.value
  } else {
    return prevActiveSection.value
  }
})

withDefaults(
  defineProps<{
    title: string
    isIconLaptop?: boolean
    isIconMeter?: boolean
    tabs: {
      label: string
      hash: string
    }[]
  }>(),
  {
    isIconLaptop: false,
    isIconMeter: false,
  },
)
</script>

<template>
  <NavigationTabs
    :title="title"
    :transitioning="transitioning"
    :tabs="tabs.map(({ hash, label }) => ({ label, href: `#${hash}`, active: hash === liveActiveSection }))"
    @click.exact.left="(evt: Event, href: string) => selectHash(evt, href.slice(1))"
  >
    <template #icon>
      <IconDeviceLaptop v-if="isIconLaptop" fill-color="jade-200" stroke-color="teal-500" />
      <IconObjectOdometer v-if="isIconMeter" fill-color="jade-200" stroke-color="teal-500" />
    </template>
  </NavigationTabs>
</template>
