//cf. https://gist.github.com/james2doyle/5694700
// requestAnimationFrame for Smart Animating https://goo.gl/sx5sts
var requestAnimFrame = (function () {
  if (typeof window === 'undefined') return
  return (
    window.requestAnimationFrame ||
    (window as any).webkitRequestAnimationFrame ||
    (window as any).mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60)
    }
  )
})()

/**
 *  * scroll body to target element
 * @param to target of the scroll
 * @param options
 */
export function scrollTo(
  to: HTMLElement | number,
  options?: { callback?: () => void; duration?: number; offset?: number },
) {
  const callback = options?.callback || (() => {})
  const duration = options?.duration || 1000
  const offset = options?.offset || 0

  function getTopOffset(element: HTMLElement) {
    const scrollMarginTop = parseInt(element.style?.scrollMarginTop, 10)
    return element.getBoundingClientRect().top + window.scrollY - (isNaN(scrollMarginTop) ? 0 : scrollMarginTop)
  }

  const toNumber = (isDomElement(to) ? getTopOffset(to) : to) + offset

  function move(amount: number) {
    document.documentElement.scrollTop = amount
    if (document.body) {
      document.body.scrollTop = amount
      ;(document.body.parentNode as any).scrollTop = amount
    }
  }

  let start = window.scrollY,
    change = toNumber - start,
    currentTime = 0,
    increment = 20

  const animateScroll = function () {
    // increment the time
    currentTime += increment
    // find the value with the quadratic in-out easing function
    var val = easeInOutQuad(currentTime, start, change, duration)
    // move the document.body
    move(val)
    // do the animation unless its over
    if (currentTime < duration && requestAnimFrame) {
      requestAnimFrame(animateScroll)
    } else {
      if (callback && typeof callback === 'function') {
        // the animation is done so lets callback
        callback()
      }
    }
  }

  if (change > 1 || change < -1) {
    animateScroll()
  }
}

function easeInOutQuad(t: number, b: number, c: number, d: number) {
  t /= d / 2
  if (t < 1) {
    return (c / 2) * t * t + b
  }
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

function isDomElement(obj: any): obj is HTMLElement {
  return obj instanceof Element
}
